<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
        <img style="max-height: 50px;" src="@/assets/images/logo/logo.png" alt=""> 

        <h5 class="brand-text text-primary ml-1 mb-5">
          Federal Ministry of  <br> <span class="">Finance, Budget and National Planning</span> 
        </h5>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h3 class="mb-1">
         Track and Follow-up your submission
        </h3>
       

        <div class="col-md-12">
            <div class="form-group">
                <label for="">Enter Tracking ID</label>
                <input type="text" class="form-control " placeholder="Enter Tracking ID">
            </div>
        </div>



        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Search
        </b-button>

        <!-- image -->
        <!-- <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        /> -->
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
